// src/AuthContext.js
import React, { createContext, useState } from 'react';

export const AuthContext = createContext();

const hardcodedUsers = [
  { username: 'admin', password: 'password123' },
  // Add more users as needed
];

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const login = ({ username, password }) => {
    const authenticatedUser = hardcodedUsers.find(
      (u) => u.username === username && u.password === password
    );
    if (authenticatedUser) {
      setUser({ username: authenticatedUser.username });
      return true;
    }
    return false;
  };

  const logout = () => {
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

