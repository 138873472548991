// src/components/Consumers.js
import React, { useEffect, useState } from 'react';
import api from '../api'; // Custom Axios instance
import Navbar from './Navbar';
import ConsumerProviderMappings from './ConsumerProviderMappings'; // Import the new component

const Consumers = () => {
  const [consumers, setConsumers] = useState([]);
  const [consumer_id, setConsumerId] = useState('');
  const [showMappingsModal, setShowMappingsModal] = useState(false);
  const [selectedConsumer, setSelectedConsumer] = useState(null);

  useEffect(() => {
    fetchConsumers();
  }, []);

  const fetchConsumers = async () => {
    try {
      const response = await api.get('/consumers');
      setConsumers(response.data);
    } catch (error) {
      console.error('Failed to fetch consumers:', error);
    }
  };

  const handleDelete = async (consumer_id) => {
    try {
      await api.delete(`/consumers/${consumer_id}`);
      fetchConsumers();
    } catch (error) {
      console.error('Failed to delete consumer:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.post('/consumers', { consumer_id });
      setConsumerId('');
      fetchConsumers();
    } catch (error) {
      console.error('Failed to add consumer:', error);
      alert('Failed to add consumer. Please check the console for more details.');
    }
  };

  const handleViewMappings = (consumer) => {
    setSelectedConsumer(consumer);
    setShowMappingsModal(true);
  };

  return (
    <>
      <Navbar />
      <div className="container mt-5">
        <h2>Consumers</h2>

        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label>Consumer ID</label>
            <input
              type="text"
              name="consumer_id"
              value={consumer_id}
              onChange={(e) => setConsumerId(e.target.value)}
              className="form-control"
              required
            />
          </div>
          <button className="btn btn-primary" type="submit">
            Add Consumer
          </button>
        </form>

        <table className="table mt-5">
          <thead>
            <tr>
              <th>Consumer ID</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {consumers.map((consumer) => (
              <tr key={consumer.consumer_id}>
                <td>{consumer.consumer_id}</td>
                <td>
                  <button
                    className="btn btn-info me-2"
                    onClick={() => handleViewMappings(consumer)}
                  >
                    View Mapped Providers
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => handleDelete(consumer.consumer_id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showMappingsModal && selectedConsumer && (
        <ConsumerProviderMappings
          consumer={selectedConsumer}
          onClose={() => {
            setShowMappingsModal(false);
            setSelectedConsumer(null);
          }}
        />
      )}
    </>
  );
};

export default Consumers;

