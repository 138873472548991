// src/components/Providers.js
import React, { useEffect, useState } from 'react';
import api from '../api';
import Navbar from './Navbar';
import ProviderDataElements from './ProviderDataElements'; // Import the new component

const Providers = () => {
  const [providers, setProviders] = useState([]);
  const [form, setForm] = useState({
    provider_id: '',
    provider_name: '',
    integration_type: '',
    integration_details: '',
  });
  const [showDataElementsModal, setShowDataElementsModal] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(null);

  useEffect(() => {
    fetchProviders();
  }, []);

  const fetchProviders = async () => {
    try {
      const response = await api.get('/providers');
      setProviders(response.data);
    } catch (error) {
      console.error('Failed to fetch providers:', error);
    }
  };

  const handleDelete = async (provider_id) => {
    try {
      await api.delete(`/providers/${provider_id}`);
      fetchProviders();
    } catch (error) {
      console.error('Failed to delete provider:', error);
    }
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const getIntegrationDetailsPlaceholder = () => {
    switch (form.integration_type) {
      case 'REST_API':
        return 'e.g., {"s3_bucket": "bucket_name", "s3_folder": "folder_name"}';
      case 'BATCH_FILE':
        return 'e.g., {"api_key": "abcd1234"}';
      case 'PROVIDER_API':
        return 'e.g., {"api_id": "api1234", "api_key": "abcd1234"}';
      default:
        return '';
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Parse integration_details as JSON if it's not empty
      const payload = { ...form };
      if (payload.integration_details) {
        payload.integration_details = JSON.parse(payload.integration_details);
      } else {
        payload.integration_details = null;
      }
      await api.post('/providers', payload);
      setForm({
        provider_id: '',
        provider_name: '',
        integration_type: '',
        integration_details: '',
      });
      fetchProviders();
    } catch (error) {
      console.error('Failed to add provider:', error);
      alert('Failed to add provider. Please check the console for more details.');
    }
  };

  const handleViewDataElements = (provider) => {
    setSelectedProvider(provider);
    setShowDataElementsModal(true);
  };

  return (
    <>
      <Navbar />
      <div className="container mt-5">
        <h2>Providers</h2>

        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label>Provider ID</label>
            <input
              type="text"
              name="provider_id"
              value={form.provider_id}
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>
          <div className="mb-3">
            <label>Provider Name</label>
            <input
              type="text"
              name="provider_name"
              value={form.provider_name}
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>
          {/* Updated Integration Type Field */}
          <div className="mb-3">
            <label>Integration Type</label>
            <select
              name="integration_type"
              value={form.integration_type}
              onChange={handleChange}
              className="form-control"
              required
            >
              <option value="">Select Integration Type</option>
              <option value="REST_API">REST_API</option>
              <option value="BATCH_FILE">BATCH_FILE</option>
              <option value="PROVIDER_API">PROVIDER_API</option>
            </select>
          </div>
          {/* Updated Integration Details Field */}
          <div className="mb-3">
            <label>Integration Details</label>
            <input
              type="text"
              name="integration_details"
              value={form.integration_details}
              onChange={handleChange}
              className="form-control"
              placeholder={getIntegrationDetailsPlaceholder()}
            />
          </div>
          <button className="btn btn-primary" type="submit">
            Add Provider
          </button>
        </form>

        {/* Displaying Providers */}
        <table className="table mt-5">
          <thead>
            <tr>
              <th>Provider ID</th>
              <th>Provider Name</th>
              <th>Integration Type</th>
              <th>Integration Details</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {providers.map((provider) => (
              <tr key={provider.provider_id}>
                <td>{provider.provider_id}</td>
                <td>{provider.provider_name}</td>
                <td>{provider.integration_type}</td>
                <td>
                  {provider.integration_details
                    ? JSON.stringify(provider.integration_details)
                    : ''}
                </td>
                <td>
                  <button
                    className="btn btn-info btn-sm me-2"
                    onClick={() => handleViewDataElements(provider)}
                  >
                    View Data Elements
                  </button>
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => handleDelete(provider.provider_id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showDataElementsModal && selectedProvider && (
        <ProviderDataElements
          provider={selectedProvider}
          onClose={() => {
            setShowDataElementsModal(false);
            setSelectedProvider(null);
          }}
        />
      )}
    </>
  );
};

export default Providers;

