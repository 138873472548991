// src/api.js
import axios from 'axios';
import config from './config';

const api = axios.create({
  baseURL: config.API_BASE_URL,
  headers: {
    'x-api-key': config.API_KEY,
  },
});

export default api;

