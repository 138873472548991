// src/components/Dashboard.js
import React from 'react';
import Navbar from './Navbar';

const Dashboard = () => {
  return (
    <>
      <Navbar />
      <div className="container mt-5">
        <h1>Welcome to the nCent Internal API Dashboard</h1>
        <p>Use the navigation bar to manage resources.</p>
      </div>
    </>
  );
};

export default Dashboard;

