// src/components/DataElementsForMapping.js
import React, { useEffect, useState } from 'react';
import api from '../api';
import { Modal, Button, Form, Table } from 'react-bootstrap';

const DataElementsForMapping = ({ consumer, mapping, onClose }) => {
  const [dataElements, setDataElements] = useState([]);
  const [providerSupportedDataElements, setProviderSupportedDataElements] = useState([]);
  const [allDataElements, setAllDataElements] = useState([]);
  const [availableDataElements, setAvailableDataElements] = useState([]);
  const [selectedDataElementId, setSelectedDataElementId] = useState('');
  const [reload, setReload] = useState(false); // State to trigger re-fetch

  // Fetch data elements mapped to the consumer-provider combination,
  // data elements supported by the provider, and all data elements
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [consumerProviderResponse, providerSupportedResponse, allDataElementsResponse] = await Promise.all([
          api.get(`/consumers/${consumer.consumer_id}/providers/${mapping.provider_id}/data-elements`),
          api.get(`/providers/${mapping.provider_id}/data-elements`),
          api.get('/data-elements'),
        ]);

        setDataElements(consumerProviderResponse.data);
        setProviderSupportedDataElements(providerSupportedResponse.data);
        setAllDataElements(allDataElementsResponse.data);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  }, [consumer.consumer_id, mapping.provider_id, reload]);

  // Compute available data elements to add:
  // Data elements supported by the provider and not already mapped
  useEffect(() => {
    const mappedDataElementIds = dataElements.map((de) => de.data_element_id);
    const supportedDataElementIds = providerSupportedDataElements.map((de) => de.data_element_id);
    const available = allDataElements.filter(
      (de) => supportedDataElementIds.includes(de.data_element_id) && !mappedDataElementIds.includes(de.data_element_id)
    );
    setAvailableDataElements(available);
  }, [providerSupportedDataElements, dataElements, allDataElements]);

  // Handle adding a new data element mapping
  const handleAddDataElement = async (e) => {
    e.preventDefault();
    try {
      await api.post(
        `/consumers/${consumer.consumer_id}/providers/${mapping.provider_id}/data-elements`,
        {
          data_element_id: selectedDataElementId,
        }
      );
      setSelectedDataElementId('');
      setReload(!reload); // Trigger re-fetch to update data
    } catch (error) {
      console.error('Failed to add data element:', error);
      alert('Failed to add data element. Please check the console for more details.');
    }
  };

  // Handle deleting an existing data element mapping
  const handleDeleteDataElement = async (data_element_id) => {
    try {
      await api.delete(
        `/consumers/${consumer.consumer_id}/providers/${mapping.provider_id}/data-elements/${data_element_id}`
      );
      setReload(!reload); // Trigger re-fetch to update data
    } catch (error) {
      console.error('Failed to delete data element:', error);
    }
  };

  return (
    <Modal show onHide={onClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title className="modal-title-custom">
          Data Elements for Mapping:
          <br />
          Consumer {consumer.consumer_id} - Provider {mapping.provider_id}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {availableDataElements.length > 0 ? (
          <Form onSubmit={handleAddDataElement}>
            <Form.Group className="mb-3">
              <Form.Label>Select Data Element to Add</Form.Label>
              <Form.Control
                as="select"
                value={selectedDataElementId}
                onChange={(e) => setSelectedDataElementId(e.target.value)}
                required
              >
                <option value="">Select Data Element</option>
                {availableDataElements.map((de) => (
                  <option key={de.data_element_id} value={de.data_element_id}>
                    {de.data_element_name} ({de.data_element_id})
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Button variant="primary" type="submit" disabled={!selectedDataElementId}>
              Add Data Element
            </Button>
          </Form>
        ) : (
          <p>No available data elements to add.</p>
        )}

        <h5 className="mt-4">Mapped Data Elements</h5>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Data Element ID</th>
              <th>Data Element Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {dataElements.map((de) => {
              const dataElement = allDataElements.find(
                (elem) => elem.data_element_id === de.data_element_id
              );
              return (
                <tr key={de.consumer_data_element_id}>
                  <td>{de.data_element_id}</td>
                  <td>{dataElement ? dataElement.data_element_name : 'Unknown'}</td>
                  <td>
                    <div className="d-flex">
                      <Button
                        variant="danger"
                        size="sm"
                        className="flex-grow-1"
                        onClick={() => handleDeleteDataElement(de.data_element_id)}
                      >
                        Delete
                      </Button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DataElementsForMapping;

