// src/components/ConsumerProviderMappings.js
import React, { useEffect, useState } from 'react';
import api from '../api';
import { Modal, Button, Form, Table } from 'react-bootstrap';
import DataElementsForMapping from './DataElementsForMapping';

const ConsumerProviderMappings = ({ consumer, onClose }) => {
  const [consumerProviders, setConsumerProviders] = useState([]);
  const [providers, setProviders] = useState([]);
  const [availableProviders, setAvailableProviders] = useState([]);
  const [selectedProviderId, setSelectedProviderId] = useState('');
  const [providerUserId, setProviderUserId] = useState('');
  const [showDataElementsModal, setShowDataElementsModal] = useState(false);
  const [selectedMapping, setSelectedMapping] = useState(null);
  const [showModal, setShowModal] = useState(true); // Control modal visibility

  useEffect(() => {
    fetchProviders();
  }, []);

useEffect(() => {
  if (providers.length > 0) {
    const fetchConsumerProviders = async () => {
      try {
        const response = await api.get(`/consumers/${consumer.consumer_id}/providers`);
        const mappedProviders = response.data;

        // Map provider names from the providers state
        const mappedProvidersWithNames = mappedProviders.map((mapping) => {
          const provider = providers.find(
            (prov) => prov.provider_id === mapping.provider_id
          );
          return {
            ...mapping,
            provider_name: provider ? provider.provider_name : 'Unknown Provider',
          };
        });

        setConsumerProviders(mappedProvidersWithNames);
      } catch (error) {
        console.error('Failed to fetch consumer-providers:', error);
      }
    };

    fetchConsumerProviders();
  }
}, [providers, consumer.consumer_id]);

  useEffect(() => {
    // Filter available providers
    const mappedProviderIds = consumerProviders.map((cp) => cp.provider_id);
    const available = providers.filter(
      (provider) => !mappedProviderIds.includes(provider.provider_id)
    );
    setAvailableProviders(available);
  }, [providers, consumerProviders]);

  const fetchProviders = async () => {
    try {
      const response = await api.get('/providers');
      setProviders(response.data);
    } catch (error) {
      console.error('Failed to fetch providers:', error);
    }
  };

  const fetchConsumerProviders = async () => {
    try {
      const response = await api.get(`/consumers/${consumer.consumer_id}/providers`);
      const mappedProviders = response.data;

      // Map provider names from the providers state
      const mappedProvidersWithNames = mappedProviders.map((mapping) => {
        const provider = providers.find(
          (prov) => prov.provider_id === mapping.provider_id
        );
        return {
          ...mapping,
          provider_name: provider ? provider.provider_name : 'Unknown Provider',
        };
      });

      setConsumerProviders(mappedProvidersWithNames);
    } catch (error) {
      console.error('Failed to fetch consumer-providers:', error);
    }
  };

  const handleAddMapping = async (e) => {
    e.preventDefault();
    try {
      await api.post(`/consumers/${consumer.consumer_id}/providers`, {
        provider_id: selectedProviderId,
        provider_user_id: providerUserId,
      });
      setSelectedProviderId('');
      setProviderUserId('');
      fetchConsumerProviders();
    } catch (error) {
      console.error('Failed to add mapping:', error);
      alert('Failed to add mapping. Please check the console for more details.');
    }
  };

  const handleDeleteMapping = async (provider_id) => {
    try {
      await api.delete(`/consumers/${consumer.consumer_id}/providers/${provider_id}`);
      fetchConsumerProviders();
    } catch (error) {
      console.error('Failed to delete mapping:', error);
    }
  };

  const handleViewDataElements = (mapping) => {
    setSelectedMapping(mapping);
    setShowDataElementsModal(true);
    setShowModal(false); // Hide the current modal
  };

  return (
    <>
      <Modal show={showModal} onHide={onClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title className="modal-title-custom">
            Provider Mappings for Consumer:
	    <br />
 	    {consumer.consumer_id}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {availableProviders.length > 0 ? (
            <Form onSubmit={handleAddMapping}>
              <Form.Group className="mb-3">
                <Form.Label>Select Provider to Map</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedProviderId}
                  onChange={(e) => setSelectedProviderId(e.target.value)}
                  required
                >
                  <option value="">Select Provider</option>
                  {availableProviders.map((provider) => (
                    <option key={provider.provider_id} value={provider.provider_id}>
                      {provider.provider_name} ({provider.provider_id})
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Provider User ID</Form.Label>
                <Form.Control
                  type="text"
                  value={providerUserId}
                  onChange={(e) => setProviderUserId(e.target.value)}
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit" disabled={!selectedProviderId}>
                Add Provider Mapping
              </Button>
            </Form>
          ) : (
            <p>No available providers to map.</p>
          )}

          <h5 className="mt-4">Mapped Providers</h5>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Provider ID</th>
                <th>Provider Name</th>
                <th>Provider User ID</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {consumerProviders.map((mapping) => (
                <tr key={mapping.consumer_provider_id}>
                  <td>{mapping.provider_id}</td>
                  <td>{mapping.provider_name}</td>
                  <td>{mapping.provider_user_id}</td>
                  <td>
                    <div className="d-flex">
                      <Button
                        variant="info"
                        size="sm"
                        className="me-2 flex-grow-1"
                        onClick={() => handleViewDataElements(mapping)}
                      >
                        Manage Data Elements
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        className="flex-grow-1"
                        onClick={() => handleDeleteMapping(mapping.provider_id)}
                      >
                        Delete
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {showDataElementsModal && selectedMapping && (
        <DataElementsForMapping
          consumer={consumer}
          mapping={selectedMapping}
          onClose={() => {
            setShowDataElementsModal(false);
            setSelectedMapping(null);
            setShowModal(true); // Show the previous modal
          }}
        />
      )}
    </>
  );
};

export default ConsumerProviderMappings;

