// src/components/Navbar.js
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';

const NavigationBar = () => {
  const { user, logout } = useContext(AuthContext);

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand as={NavLink} to="/">
          nCent Internal API Dashboard
        </Navbar.Brand>
        {user && (
          <>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link as={NavLink} to="/providers">
                  Providers
                </Nav.Link>
                <Nav.Link as={NavLink} to="/consumers">
                  Consumers
                </Nav.Link>
                <Nav.Link as={NavLink} to="/data-elements">
                  Data Elements
                </Nav.Link>
                {/* Remove the Mappings link */}
                {/* <Nav.Link as={NavLink} to="/mappings">
                  Mappings
                </Nav.Link> */}
              </Nav>
              <Button variant="outline-secondary" onClick={logout}>
                Logout
              </Button>
            </Navbar.Collapse>
          </>
        )}
      </Container>
    </Navbar>
  );
};

export default NavigationBar;

