// src/components/DataElements.js
import React, { useEffect, useState } from 'react';
import api from '../api';
import Navbar from './Navbar';

const DataElements = () => {
  const [dataElements, setDataElements] = useState([]);
  const [form, setForm] = useState({
    data_element_name: '',
    standard_unit: '',
    data_type: '',
    min_value: '',
    max_value: '',
    format: '',
  });

  useEffect(() => {
    fetchDataElements();
  }, []);

  const fetchDataElements = async () => {
    try {
      const response = await api.get('/data-elements');
      setDataElements(response.data);
    } catch (error) {
      console.error('Failed to fetch data elements:', error);
    }
  };

  const handleDelete = async (data_element_id) => {
    try {
      await api.delete(`/data-elements/${data_element_id}`);
      fetchDataElements();
    } catch (error) {
      console.error('Failed to delete data element:', error);
    }
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Convert min_value and max_value to numbers if they are provided
      const payload = { ...form };
      if (payload.min_value === '') {
        delete payload.min_value;
      } else {
        payload.min_value = Number(payload.min_value);
      }
      if (payload.max_value === '') {
        delete payload.max_value;
      } else {
        payload.max_value = Number(payload.max_value);
      }
      await api.post('/data-elements', payload);
      setForm({
        data_element_name: '',
        standard_unit: '',
        data_type: '',
        min_value: '',
        max_value: '',
        format: '',
      });
      fetchDataElements();
    } catch (error) {
      console.error('Failed to add data element:', error);
      alert('Failed to add data element. Please check the console for more details.');
    }
  };

  return (
    <>
      <Navbar />
      <div className="container mt-5">
        <h2>Data Elements</h2>

        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label>Data Element Name</label>
            <input
              type="text"
              name="data_element_name"
              value={form.data_element_name}
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>
          <div className="mb-3">
            <label>Standard Unit</label>
            <input
              type="text"
              name="standard_unit"
              value={form.standard_unit}
              onChange={handleChange}
              className="form-control"
              required
            />
          </div>
          {/* Updated Data Type Field */}
          <div className="mb-3">
            <label>Data Type</label>
            <select
              name="data_type"
              value={form.data_type}
              onChange={handleChange}
              className="form-control"
              required
            >
              <option value="">Select Data Type</option>
              <option value="string">string</option>
              <option value="decimal">decimal</option>
              <option value="integer">integer</option>
              <option value="boolean">boolean</option>
            </select>
          </div>
          {/* Rest of the form fields */}
          <div className="mb-3">
            <label>Min Value</label>
            <input
              type="number"
              name="min_value"
              value={form.min_value}
              onChange={handleChange}
              className="form-control"
            />
          </div>
          <div className="mb-3">
            <label>Max Value</label>
            <input
              type="number"
              name="max_value"
              value={form.max_value}
              onChange={handleChange}
              className="form-control"
            />
          </div>
          <div className="mb-3">
            <label>Format</label>
            <input
              type="text"
              name="format"
              value={form.format}
              onChange={handleChange}
              className="form-control"
            />
          </div>
          <button className="btn btn-primary" type="submit">
            Add Data Element
          </button>
        </form>

        {/* Displaying Data Elements */}
        <table className="table mt-5">
          <thead>
            <tr>
              <th>Data Element ID</th>
              <th>Data Element Name</th>
              <th>Standard Unit</th>
              <th>Data Type</th>
              <th>Min Value</th>
              <th>Max Value</th>
              <th>Format</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {dataElements.map((de) => (
              <tr key={de.data_element_id}>
                <td>{de.data_element_id}</td>
                <td>{de.data_element_name}</td>
                <td>{de.standard_unit}</td>
                <td>{de.data_type}</td>
                <td>{de.min_value}</td>
                <td>{de.max_value}</td>
                <td>{de.format}</td>
                <td>
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => handleDelete(de.data_element_id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default DataElements;

