// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import PrivateRoute from './components/PrivateRoute';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Providers from './components/Providers';
import Consumers from './components/Consumers';
import DataElements from './components/DataElements';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/providers"
            element={
              <PrivateRoute>
                <Providers />
              </PrivateRoute>
            }
          />
          <Route
            path="/consumers"
            element={
              <PrivateRoute>
                <Consumers />
              </PrivateRoute>
            }
          />
          <Route
            path="/data-elements"
            element={
              <PrivateRoute>
                <DataElements />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;

