// src/components/ProviderDataElements.js
import React, { useEffect, useState } from 'react';
import api from '../api';
import { Modal, Button, Form, Table } from 'react-bootstrap';

const ProviderDataElements = ({ provider, onClose }) => {
  const [providerDataElements, setProviderDataElements] = useState([]);
  const [allDataElements, setAllDataElements] = useState([]);
  const [availableDataElements, setAvailableDataElements] = useState([]);
  const [selectedDataElementId, setSelectedDataElementId] = useState('');
  const [providerUnit, setProviderUnit] = useState('');
  const [reload, setReload] = useState(false); // State to trigger re-fetch

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [providerDataElementsResponse, allDataElementsResponse] = await Promise.all([
          api.get(`/providers/${provider.provider_id}/data-elements`),
          api.get('/data-elements'),
        ]);

        setProviderDataElements(providerDataElementsResponse.data);
        setAllDataElements(allDataElementsResponse.data);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  }, [provider.provider_id, reload]);

  useEffect(() => {
    // Filter available data elements
    const mappedDataElementIds = providerDataElements.map((de) => de.data_element_id);
    const available = allDataElements.filter(
      (de) => !mappedDataElementIds.includes(de.data_element_id)
    );
    setAvailableDataElements(available);
  }, [allDataElements, providerDataElements]);

  // Handle change in selected data element
  const handleDataElementChange = (e) => {
    const selectedId = e.target.value;
    setSelectedDataElementId(selectedId);

    // Find the selected data element to get its standard_unit
    const selectedDataElement = allDataElements.find(
      (de) => de.data_element_id === selectedId
    );
    if (selectedDataElement) {
      setProviderUnit(selectedDataElement.standard_unit || '');
    } else {
      setProviderUnit('');
    }
  };

  const handleAddDataElement = async (e) => {
    e.preventDefault();
    try {
      await api.post(`/providers/${provider.provider_id}/data-elements`, {
        data_element_id: selectedDataElementId,
        provider_unit: providerUnit,
      });
      setSelectedDataElementId('');
      setProviderUnit('');
      setReload(!reload); // Trigger re-fetch to update data
    } catch (error) {
      console.error('Failed to add data element to provider:', error);
      alert('Failed to add data element. Please check the console for more details.');
    }
  };

  const handleDeleteDataElement = async (data_element_id) => {
    try {
      await api.delete(
        `/providers/${provider.provider_id}/data-elements/${data_element_id}`
      );
      setReload(!reload); // Trigger re-fetch to update data
    } catch (error) {
      console.error('Failed to remove data element from provider:', error);
    }
  };

  return (
    <Modal show onHide={onClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          Data Elements for Provider: {provider.provider_name} ({provider.provider_id})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {availableDataElements.length > 0 ? (
          <Form onSubmit={handleAddDataElement}>
            <Form.Group className="mb-3">
              <Form.Label>Select Data Element to Add</Form.Label>
              <Form.Control
                as="select"
                value={selectedDataElementId}
                onChange={handleDataElementChange}
                required
              >
                <option value="">Select Data Element</option>
                {availableDataElements.map((de) => (
                  <option key={de.data_element_id} value={de.data_element_id}>
                    {de.data_element_name} ({de.data_element_id})
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Provider Unit</Form.Label>
              <Form.Control
                type="text"
                value={providerUnit}
                onChange={(e) => setProviderUnit(e.target.value)}
                required
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              disabled={!selectedDataElementId || !providerUnit}
            >
              Add Data Element
            </Button>
          </Form>
        ) : (
          <p>No available data elements to add.</p>
        )}

        <h5 className="mt-4">Mapped Data Elements</h5>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Data Element ID</th>
              <th>Data Element Name</th>
              <th>Provider Unit</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {providerDataElements.map((de) => {
              const dataElement = allDataElements.find(
                (elem) => elem.data_element_id === de.data_element_id
              );
              return (
                <tr key={de.provider_data_element_id}>
                  <td>{de.data_element_id}</td>
                  <td>{dataElement ? dataElement.data_element_name : 'Unknown'}</td>
                  <td>{de.provider_unit}</td>
                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDeleteDataElement(de.data_element_id)}
                    >
                      Remove
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProviderDataElements;

